footer{
    width: 100%;
    height: 60px;
    background-color: #8F8F8F;
    margin-top: 20px;
    position: absolute;
    box-sizing: border-box;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
}