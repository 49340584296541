.body-container{
    width: 70%;
    max-width: 1344px;
    display: flex;
    justify-content: center;
    margin:auto;
    flex-direction: column;
    min-height: 100%;
    position: relative;
    padding-bottom: 80px;
}

.App{
    position: relative;
    margin: 0;
    min-height: 100vh;
}
html {
    height: 100%;
    box-sizing: border-box;
}
  

@media screen and (max-width: 1140px){
    .body-container{
        width:90%;
    }
}