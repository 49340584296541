#knifeForm{
    width: 100%;
    display: flex;
    border:solid #ccc 2px;
    border-radius:5px;
    border-style:outset;
    margin-top: 20px;

}

.img-stuff{
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border:solid #ccc 2px;
    border-radius:5px;
    border-style:outset;
    margin: 20px;
}

#rest-of-form{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.input{
    width: 50%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px;
}

.input input{
    width:100%;;
}

#form-img{
    max-width: 300px;
    max-height: 300px;
}


@media screen and (max-width: 700px){
    #knifeForm{
        flex-direction: column;
        flex-wrap: wrap;
    }
    .img-stuff{
        width: 100%;
        border: none;
        justify-content: center;
    }
    #rest-of-form{
        flex-direction: column;
        width: 100%
    }
    #name{
        width:100%;
    }
    .input{
        width: 100%;
    }
    button{
        width: 100%;
    }
}