header{
    display: flex;
    justify-content: space-around;
    width: 100%;
    background-color: #8F8F8F;
    height: 60px;
    align-items: center;
    position: sticky;
    top:0px;
    z-index: 100;
}

#header-title{
    color: white;
    font-weight: bold;
    font-size: 2em;
}

nav span{
    margin: 0px 10px;
    cursor: pointer;
    font-weight: bold;
    width: 60px;
    display: inline-block;
    text-align: center;
}